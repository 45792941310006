import { type ProductType } from './product-types';


type ProductCategory = {
  name: string;
  products: ProductType[];
};

const productGroupMap = [
  {
    name: 'wall-art',
    products: [
      'poster',
      'leinwand',
      'acrylglas',
      'aludibond',
      'hahnemuehle',
      'forexplatte',
    ],
  },
  {
    name: 'apparel',
    products: [
      't-shirt',
      'hoodie',
      'sweatshirt',
      't-shirt-fitted-woman',
    ],
  },
  {
    name: 'apparel-kid',
    products: [
      't-shirt-kid',
      'hoodie-kid',
      'sweatshirt-kid',
    ],
  },
  {
    name: 'apparel-baby',
    products: [
      'romper-short-sleeve-baby',
      'romper-long-sleeve-baby',
      'bib-baby',
      't-shirt-baby',
      'sweatshirt-baby',
    ],
  },
  {
    name: 'cases-phone',
    products: [
      'phone-cover-iphone',
      'phone-cover-samsung',
    ],
  },
  {
    name: 'sticker',
    products: [
      'sticker',
      'sticker-sheet',
    ],
  },
  {
    name: 'stationery',
    products: [
      'notebook',
      'notebook-spiral',
    ],
  },
  {
    name: 'home-kitchen',
    products: [
      'bag-tote',
      'curtain-shower',
      'doormat',
      'mug-ceramic',
      'mug-enamel',
    ],
  },
  {
    name: 'textiles-home',
    products: [
      'blanket-fleece',
      'cushion-decorative',
    ],
  },
  {
    name: 'towels',
    products: [
      'towel-bath',
      'towel-beach',
      'towel-tea',
    ],
  },
  {
    name: 'backlit-films',
    products: [ 'backlitfolie' ],
  },
  {
    name: 'pvcplane',
    products: [ 'pvcplane' ],
  },
  {
    name: 'fototapete',
    products: [ 'fototapete' ],
  },
] as const satisfies ProductCategory[];

type ProductCategoryName = (typeof productGroupMap)[number]['name'];


export { productGroupMap, type ProductCategoryName };
